const $ = require('jquery');
import './styles/app.scss';
//require('bootstrap');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
require ('bootstrap-select');  
require('bootstrap-select/dist/css/bootstrap-select.min.css');

import echo from "echo-js";
require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.js');

$(document).ready(function() {
    echo.init({
        offset: 100,
        throttle: 250,
        unload: true
    });
    
    $('.right-scroll-container').on('scroll',function(){
        echo.render();
    }); 

	$('.main-scroll-container').on('scroll',function(){
        echo.render();
    }); 

    $("img").each(function(i, o){
		$(o).click(function(){
			if ($(o).attr('href-zoom') != undefined && $(o).attr('href-zoom') != '')
			{
                modalWindow($(o).attr('href-zoom'), $(o).attr('title-zoom'), null, null, null, null);
			}
		});
	});

	$('.selectpicker').selectpicker(); 
});

function alertMessage(message, confirm_text, callbackyes)
{
    if(!message) message = "";
    if(!confirm_text) confirm_text = "Ok";
    if(!callbackyes) callbackyes = function(){};
    
    $('#modal_text').text(message);
    $('#modal_btn_yes').text(confirm_text);
    $('#modal_btn_no').hide();

    $("#modal_btn_yes").on("click", function(){
        callbackyes();
        $("#modal_dialog").modal('hide');
    });
    
    $("#modal_dialog").modal('show');
}

function modalWindow(path, title, buttonOkDescription, buttonCancelDescription, buttonOkCallback, buttonCancelCallback, hide_close_button)
{ 
	if(!title) var title = "";
	if(!buttonOkDescription) var buttonOkDescription = "Ok";
	if(!buttonCancelDescription) var buttonCancelDescription = "Chiudi";
	if(!buttonCancelCallback) buttonCancelCallback = function(){};
	if(!buttonOkCallback) {
		$('#content_modal_btn_yes').hide();
	} else {
		$('#content_modal_btn_yes').show();
	}
	if(!hide_close_button) hide_close_button = false;

	$('#content_modal_dialog_title').html(title);
	$('#content_modal_btn_yes').text(buttonOkDescription);
    $('#content_modal_btn_no').text(buttonCancelDescription);

	var dialog_container = $('#content_modal_dialog_container');
	dialog_container.empty();
	
	if(hide_close_button)
	{
		$("#content_modal_dialog").modal({backdrop: 'static', keyboard: false, show:true});
		$("#content_modal_dialog").modal("show");
	} else {
		$("#content_modal_dialog").modal("show");
	}

	dialog_container.load(path);
	
	$('#content_modal_dialog').on('hidden.bs.modal', function () {
		$("#content_modal_btn_yes").unbind();
		$("#content_modal_btn_no").unbind(); 
		$('body').removeClass('modal-open');
	});

	$('#content_modal_dialog').on('shown.bs.modal', function () {
		refreshSelectPicker();
	});

	$("#content_modal_btn_yes").on("click", function(){
        buttonOkCallback();
        //$("#content_modal_dialog").modal('hide');
    });

	$("#content_modal_btn_no").on("click", function(){
		buttonCancelCallback();
        $("#content_modal_dialog").modal('hide');
    });
}

function refreshSelectPicker()
{
	$('.selectpicker').selectpicker();
}

function updateTextAreaCounter()
{
	$('textarea').each(function() {
		let char_count = $(this).val().length;
    	$('#'+ $(this).attr("id") +'_char_count').html(char_count);
	});
}

function closeModalWindow()
{
	$("#content_modal_dialog").modal('hide');
}

function getPostObject(obj)
{
	var data = new Object();	
	$(obj).find('input[type="hidden"]').each(function(idx, o){
		if($(o).attr('name'))
		{
			
			if($(o).attr('name').substr(-2) == '[]')
			{
				if (!data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)])
				{
					data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)] = new Array();
					data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)].push($(o).val());
				} else {
					data[$(o).attr('name').substr(0, $(o).attr('name').length- 2)].push($(o).val());
				}
			} else {
				data[$(o).attr('name')] = $(o).val();				
			}

		}
	});
	
	$(obj).find('input[type="text"]').each(function(idx, o){
		if($(o).attr('name'))
		{
			if($(o).attr('name').substr(-2) == '[]')
			{
				if (!data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)])
				{
					data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)] = new Array();
					data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)].push($(o).val());
				} else {
					data[$(o).attr('name').substr(0, $(o).attr('name').length- 2)].push($(o).val());
				}
			} else {
				data[$(o).attr('name')] = $(o).val();				
			}
		}
	});

	$(obj).find('input[type="number"]').each(function(idx, o){
		if($(o).attr('name'))
		{
			if($(o).attr('name').substr(-2) == '[]')
			{
				if (!data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)])
				{
					data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)] = new Array();
					data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)].push($(o).val());
				} else {
					data[$(o).attr('name').substr(0, $(o).attr('name').length- 2)].push($(o).val());
				}
			} else {
				data[$(o).attr('name')] = $(o).val();				
			}
		}
	});
	
	$(obj).find('textarea').each(function(idx, o){
		if($(o).attr('name'))
		{
			if($(o).attr('name').substr(-2) == '[]')
			{
				if (!data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)])
				{
					data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)] = new Array();
					data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)].push($(o).val());
				} else {
					data[$(o).attr('name').substr(0, $(o).attr('name').length- 2)].push($(o).val());
				}
			} else {
				data[$(o).attr('name')] = $(o).val();				
			}
		}
	});
	
	$(obj).find('input[type="checkbox"]').each(function(idx, o){
		if($(o).attr('name'))
		{
			if($(o).prop('checked'))
			{
				if($(o).attr('name').substr(-2) == '[]')
				{
					if (!data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)])
					{
						data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)] = new Array();
						data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)].push($(o).val());
					} else {
						data[$(o).attr('name').substr(0, $(o).attr('name').length- 2)].push($(o).val());
					}
				} else {
					data[$(o).attr('name')] = $(o).val();				
				}
			}	
		}
	});

	$(obj).find('input[type="radio"]').each(function(idx, o){
		if($(o).attr('name'))
		{
			if($(o).prop('checked'))
			{
				if($(o).attr('name').substr(-2) == '[]')
				{
					if (!data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)])
					{
						data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)] = new Array();
						data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)].push($(o).val());
					} else {
						data[$(o).attr('name').substr(0, $(o).attr('name').length- 2)].push($(o).val());
					}
				} else {
					data[$(o).attr('name')] = $(o).val();				
				}
			}	
		}
	});
	
	$(obj).find('select').each(function(idx, o){
		//data[$(o).attr('name')] = '';
		if ($(o).find('option:selected').length > 0)
		{
			if($(o).attr('name').substr(-2) == '[]')
			{
				if (!data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)])
				{

					data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)] = new Array();
					$(o).find('option:selected').each(function(io,oo){
						data[$(o).attr('name').substr(0, $(o).attr('name').length - 2)].push($(oo).val());	
					});
					
				} else {
					$(o).find('option:selected').each(function(io,oo){
						data[$(o).attr('name').substr(0, $(o).attr('name').length- 2)].push($(oo).val());
					});
				}
			} else {				
				data[$(o).attr('name')] = $(o).find('option:selected').val();				
			}
		}
	});
	
	return data;
}

function flashMessage()
{
	let duration = 1500;
	if($(".flash-message").hasClass("error"))
	{
		duration = 3000;
 	}
	$('.flash-message').slideDown(400, function(){
			setTimeout(function(){
					$('.flash-message').slideUp("slow", function(){
						$('.flash-message').remove();
					});
				},duration);
			
		});
}

function addFlashMessage(custom_class, message)
{
	$('body').prepend('<div class="flash-message ' + custom_class + '">' + message + '</div>');
}

function confirmMessage(message, confirm_text, cancel_text, callbackyes, callbackno)
{
    if(!message) message = "Confermi?";
    if(!confirm_text) confirm_text = "Si";
    if(!cancel_text) cancel_text = "No";
    if(!callbackyes) callbackyes = function(){};
    if(!callbackno) callbackno = function(){};
    
    $('#modal_text').text(message);
    $('#modal_btn_yes').text(confirm_text);
    $('#modal_btn_no').text(cancel_text);
	$('#modal_btn_no').show();
    
    $("#modal_btn_yes").on("click", function(){
        callbackyes();
        $("#modal_dialog").modal('hide');
    });
    
    $("#modal_btn_no").on("click", function(){
        callbackno();
        $("#modal_dialog").modal('hide');
    });

	$('#modal_dialog').on('hidden.bs.modal', function () {
		$("#modal_btn_yes").unbind();
		$("#modal_btn_no").unbind();

		if ($('#content_modal_dialog:visible').length) 
		{ 
			$('body').addClass('modal-open');
		}
	});
    
    $("#modal_dialog").modal('show');
}

function saveReorderLabel(check_exist_article, reload_page)
{
	if(check_reorder_data())
	{
		if(check_exist_article)
		{
			$.ajax({
				type: 'POST',
				url: $('#app_check_cart_item_path').val(),
				data: {
					data: getPostObject("[name='reorder-form']")
				},
				dataType: 'json',
				success: function (data) 
				{                            
					switch(data.already_present)
					{
						case 0:
							sendReorderData(reload_page);
						break;
						case 1:
							confirmMessage("E' già presente un articolo uguale nel carrello. Vuoi aggiungere la quantità all'articolo esistente oppure inserirlo come nuovo?", "Aggiunti quantità", "Come nuovo", function(){
								$('#reorder_type_save').val("APP");
								sendReorderData(reload_page);
							}, function(){
								$('#reorder_type_save').val("NEW");
								sendReorderData(reload_page);
							});
						break;
						default:
							closeModalWindow();		
							alertMessage('Errore durante l\'aggiunta al carrello .', 'Ok', null);		
						break;
					}
				},
				error: function() {
					closeModalWindow();
					alertMessage('Errore durante l\'aggiunta al carrello .', 'Ok', null);
				}
			});
		} else {
			sendReorderData(reload_page);
		}
	}
}

function sendReorderData(reload_page)
{
	$.ajax({
		type: 'POST',
		url: $('#app_add_cart_item_path').val(),
		data: {
			data: getPostObject("[name='reorder-form']")
		},
		dataType: 'json',
		success: function (data) {                            
			closeModalWindow();
			switch(data.error_code)
			{
				case 0:
					addFlashMessage("ok", "Aggiunto al carrello");
					flashMessage();
					if(reload_page)
					{
						location.reload();
					} else {
						if($('#reorder_type_save').val() != "APP")
						{
							$('#cart-items-number').css('display','block');
							$('#cart-items-number').html(parseInt($('#cart-items-number').html())+1);
						}
					}
				break;
				default:
					closeModalWindow();
					alertMessage('Errore durante l\'aggiunta al carrello .', 'Ok', null);
				break;
			}
		},
		error: function() {
			closeModalWindow();
			alertMessage('Errore durante l\'aggiunta al carrello .', 'Ok', null);
		}
	});
}

function check_reorder_data()
{
    document.querySelector('#reorder_amount').reportValidity();
    let is_valid = true;

    if(!(parseInt($('#reorder_amount').val()) > 0))
    {
        is_valid = false
		
		document.querySelector('#reorder_amount').setCustomValidity("Inserisci una quantità");
    }

    return is_valid;
}

window.modalWindow = modalWindow;
window.closeModalWindow = closeModalWindow;
window.alertMessage = alertMessage;
window.getPostObject = getPostObject;
window.flashMessage = flashMessage;
window.addFlashMessage = addFlashMessage;
window.confirmMessage = confirmMessage;
window.saveReorderLabel = saveReorderLabel;
window.updateTextAreaCounter = updateTextAreaCounter;
window.refreshSelectPicker = refreshSelectPicker;